import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AppFullComponent } from "./layouts/full/full.component";
import { AuthGuard } from "./share/service/guard.service";

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: AppFullComponent,
    children: [
      {
        path: "",
        redirectTo: "/motor/policies",
        pathMatch: "full",
      },
      {
        path: "motor",
        loadChildren: () =>
          import("./motor/motor.module").then((m) => m.MotorModule),
      },
    ],
  },
  {
    path: "",
    component: AppBlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "authentication/404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

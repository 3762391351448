import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  UserListingInterface,
  UserProfileInterface,
} from "../interface/user.interface";

@Injectable({
  providedIn: "root",
})
export class UserDataService {
  private basePath: string = `${environment.url}/api/PartnerUser/`;

  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    return this.http.get(
      `${this.basePath}Login?email=${email}&password=${password}`,
      { responseType: "text" }
    );
  }

  getProfile() {
    return this.http.get<UserProfileInterface>(`${this.basePath}GetProfile`);
  }

  getListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string
  ) {
    return this.http.post<{
      count: number;
      data: UserListingInterface[];
    }>(`${this.basePath}GetUserListing`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
    });
  }

  createUser(
    name: string,
    email: string,
    motorPartnerGroupPartnerIds: string[],
    password: string
  ) {
    return this.http.post(`${this.basePath}AddUser`, {
      email,
      name,
      motorPartnerGroupPartnerIds,
      password,
    });
  }

  editUser(
    id: string,
    name: string,
    email: string,
    motorPartnerGroupPartnerIds: string[],
    password: string
  ) {
    return this.http.post(`${this.basePath}UpdateUserDetails/${id}`, {
      name,
      email,
      motorPartnerGroupPartnerIds,
      password,
    });
  }

  removeUser(id: string) {
    return this.http.post(`${this.basePath}UpdateStatus/${id}`, null);
  }

  updatePassword(values: {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }) {
    return this.http.post(`${this.basePath}UpdatePassword`, values);
  }
}

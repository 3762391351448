import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../share/service/auth.service";
import { UserService } from "src/app/share/service/user.service";

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
})
export class AppDrawerComponent implements OnInit {
  tab: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes("motor")) {
      this.tab = "motor";
    }
    if (this.router.url.includes("settings")) {
      this.tab = "settings";
    }
    if (this.router.url.includes("report")) {
      this.tab = "reports";
    }
    if (this.router.url.includes("accounts")) {
      this.tab = "accounts";
    }
    if (this.router.url.includes("rider-accident")) {
      this.tab = "rider-accident";
    }
    if (this.router.url.includes("ecommerce")) {
      this.tab = "ecommerce";
    }
  }

  logout() {
    this.authService.logout();
  }
}

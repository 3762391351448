<div>
  <form [formGroup]="frmGrp" (ngSubmit)="save()">
    <div mat-dialog-title>{{data?.title}}</div>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <div class="form-field">
        <mat-form-field>
          <mat-label>{{data?.textLabel ?? "Name"}}</mat-label>
          <input
            autofocus
            matInput
            formControlName="name"
            [placeholder]="data?.textPlaceholder"
            required
          />
          <mat-error>{{data?.textLabel ?? "Name"}} is required</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-button class="sm" [mat-dialog-close]="false">
        {{data?.cancelBtnText ?? "Cancel"}}
      </button>
      <button mat-flat-button color="primary" class="sm">
        {{data?.okBtnText ?? "Save"}}
      </button>
    </div>
  </form>
</div>

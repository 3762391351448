<div class="drawer-content">
  <mat-nav-list>
    <mat-accordion>
      <!-- <mat-expansion-panel [expanded]="tab == 'settings'">
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title>
            <mat-icon mat-list-icon>local_police</mat-icon>
            <span style="margin-left: 12px; font-size: 16px"
              >Access Control</span
            >
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item
          routerLinkActive="active"
          routerLink="/settings/users"
          appAppDrawer
        >
          <mat-icon mat-list-icon>people</mat-icon>
          <div class="title" mat-line>Internal Users</div>
        </mat-list-item>
      </mat-expansion-panel> -->

      <mat-list-item
        routerLinkActive="active"
        routerLink="/motor/policies"
        appAppDrawer
      >
        <mat-icon mat-list-icon>check_circle</mat-icon>
        <div class="title" mat-line>Policies</div>
      </mat-list-item>

      <mat-list-item
        routerLinkActive="active"
        routerLink="/motor/user-access"
        *ngIf="userService?.user?.isSuperAdmin"
        appAppDrawer
      >
        <mat-icon mat-list-icon>settings</mat-icon>
        <div class="title" mat-line>User Access</div>
      </mat-list-item>
    </mat-accordion>
  </mat-nav-list>
</div>

<mat-toolbar class="header">
  <div class="menu-container">
    <button mat-icon-button (click)="drawerService.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <img src="assets/image/ps-logo.png" class="logo" />

    <span fxFlex></span>
    <mat-chip-list class="profile-avatar" *ngIf="userService?.user">
      <mat-chip [matMenuTriggerFor]="menu">
        {{ userService?.user?.name }}
      </mat-chip>
    </mat-chip-list>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="authService.changePassword()"
        >Change password</button
      >
      <button mat-menu-item (click)="authService.logout()">Logout</button>
    </mat-menu>
  </div>
</mat-toolbar>

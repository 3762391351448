import { Injectable } from "@angular/core";
import { UserDataService } from "../data-service/user.data.service";
import { UserProfileInterface } from "../interface/user.interface";

@Injectable({
    providedIn: 'root',
})
export class UserService {

    user: UserProfileInterface;

    constructor(private userDataService: UserDataService) {
    }
    onInit() {
        this.updateProfile();
    }
    updateProfile() {
        this.userDataService.getProfile().subscribe(x => {
            this.user = x;
        });
    }
}
import { Component } from "@angular/core";
import { AuthService } from "src/app/share/service/auth.service";
import { UserService } from "src/app/share/service/user.service";
import { DrawerService } from "../../share/service/drawer.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: [],
})
export class AppHeaderComponent {
  constructor(
    public drawerService: DrawerService,
    public userService: UserService,
    public authService: AuthService
  ) {}
}

import { AfterContentChecked, Component, HostListener, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { UserService } from "../../share/service/user.service";
import { DrawerService } from "../../share/service/drawer.service";


@Component({
  selector: "app-full",
  templateUrl: "./full.component.html",
  styleUrls: [],
})
export class AppFullComponent implements AfterContentChecked {
  @ViewChild("drawer") public drawer: MatDrawer;
  screenWidth: number;
  constructor(
    private drawerService: DrawerService,
    private userService: UserService
  ) {
    this.screenWidth = window.innerWidth;

    this.userService.onInit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }
  ngAfterContentChecked(): void {
    this.drawerService.drawer = this.drawer;
  }
}

import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface InfoDialogInterface {
  text: string;
  textBold: string;
}

@Component({
  selector: "app-info-dialog",
  templateUrl: "./info-dialog.component.html",
  styleUrls: ["./info-dialog.component.scss"],
})
export class InfoDialogComponent implements OnInit {
  data: InfoDialogInterface;

  constructor(@Inject(MAT_DIALOG_DATA) data: InfoDialogInterface) {
    this.data = data;
  }

  ngOnInit(): void {}
}

<mat-toolbar class="sidenav-header">
  <div class="menu-container">
    <button
      mat-icon-button
      class="pg-header-btn"
      mat-dialog-close
      (click)="close($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <div class="pg-title">Change password</div>
    <span fxFlex></span>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="form-field">
    <mat-form-field>
      <mat-label>Current password</mat-label>
      <input
        formControlName="currentPassword"
        matInput
        [type]="hide ? 'password' : 'text'"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      <mat-error>Current password is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>New password</mat-label>
      <input
        formControlName="newPassword"
        (ngModelChange)="getNewPasswordLength($event)"
        matInput
        [type]="hide ? 'password' : 'text'"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      <mat-error *ngIf="(f.newPassword.dirty || submitted) && !requiredValid"
        >New password is required</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Repeat new password</mat-label>
      <input
        formControlName="repeatNewPassword"
        (ngModelChange)="getRepeatNewPasswordLength($event)"
        matInput
        [type]="hide ? 'password' : 'text'"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      <mat-error>Repeat new password is required</mat-error>
    </mat-form-field>
    <mat-error
      *ngIf="
        (f.newPassword.dirty &&
          f.newPassword.errors &&
          f.newPassword.errors.requiresDigit) ||
        (f.newPassword.dirty &&
          f.newPassword.errors &&
          f.newPassword.errors.requiresLowercase) ||
        (f.newPassword.dirty &&
          f.newPassword.errors &&
          f.newPassword.errors.requiresSpecialChars)
      "
      translate
    >
      Your new password must be an alphanumeric.
    </mat-error>
    <mat-error
      *ngIf="
        (f.newPassword.dirty && !newPassLength) ||
        (f.repeatNewPassword.dirty && !repeatNewPassLength)
      "
      translate
    >
      Your new password must be at least 8 characters long.
    </mat-error>
    <mat-error
      *ngIf="
        f.newPassword.dirty &&
        f.newPassword.errors &&
        f.newPassword.errors.requiresSpecialChars
      "
      translate
    >
      Your new password must contain at least one special character.
    </mat-error>
    <mat-error
      *ngIf="
        (submitted || f.repeatNewPassword.dirty) &&
        f.repeatNewPassword.errors &&
        f.repeatNewPassword.errors.required
      "
      translate
    >
      Confirm your password
    </mat-error>
    <!-- <mat-error
      *ngIf="
        (submitted || f.repeatNewPassword.dirty) &&
        f.repeatNewPassword.errors &&
        f.repeatNewPassword.errors.mismatch
      "
      translate
    >
      Passwords do not match
    </mat-error> -->
    <button
      mat-raised-button
      color="primary"
      class="full-width mt-md"
      [disabled]="formGroup.invalid"
    >
      Update
    </button>
  </div>
</form>
